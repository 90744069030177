import { i18n } from '@/ui/locales/setupLocale';
import { notification } from 'ant-design-vue';
import { genericError } from './genericError';

export enum ApiErrors {
  oddMissing = 'ERR_ODDS_MISSING',
  antepostOrderAndName = 'ERR_ORDER_AND_NAME_UNIQUE_PER_COMPETITION',
  antepostHasBets = 'ERR_HAS_ANTEPOST_BETS',
  antepostNoBets = 'ERR_NO_ANTEPOST_BETS',
  errExpired = 'ERR_EXPIRED',
  hasAteposts = 'ERR_HAS_ANTEPOSTS',
  eventExist = 'ERR_EVENT_EXISTS',
  oddMustBeGt1 = 'ERR_ODD_MUST_BE_GT_1',
  dateinThePast = 'ERR_END_DATE_IN_THE_PAST',
  antepostNameMustBeUnique = 'ERR_ANTEPOST_NAME_UNIQUE_PER_MARKET',
  favoriteNoLocation = 'ERR_NO_LOCATION',
  cancelLiveTimeOut = 'ERR_TIMEFRAME_FOR_ROLLBACK_PASSED',
}

const forbiddenMessage = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: i18n.t('common.forbidden') as string,
  });
};

const oddMissingMessage = () => {
  notification.error({
    message: i18n.t('common.oddMissing') as string,
    description: i18n.t('common.oddMissingMessage') as string,
  });
};

const antepostOrderAndNameError = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: i18n.t('antepost.validationErrorOrderAndName') as string,
  });
};

const antepostHasBets = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: i18n.t('antepost.hasAntepostBets') as string,
  });
};

const antepostNoBets = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: i18n.t('antepost.noAtepostBets') as string,
  });
};

const onErrorExpired = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: i18n.t('slip.errExpired') as string,
  });
};

const unableToDeteleHasAnteposts = ({ antepost_market }: { antepost_market: number }) => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: `Antepost market has ${antepost_market} Anteposts in the offer.`,
  });
};

const eventAlreadyExist = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: `Event already exist.`,
  });
};

const oddMustBeGt1 = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: `Odd must be greater than 1.`,
  });
};

const dateInPast = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: `Date is in past.`,
  });
};

const antepostNameMustBeUnique = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: `Antepost name must be unique per market`,
  });
};

const favoriteNoLocation = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: `Competition without location can't be favorite.`,
  });
};

const cancelLiveTimeOut = () => {
  notification.error({
    message: i18n.t('common.error') as string,
    description: `Live slips that are older than 10 minutes cannot be cancelled .`,
  });
};

export const errorMessage: any = {
  genericError,
  403: forbiddenMessage,
  [ApiErrors.oddMissing]: oddMissingMessage,
  [ApiErrors.antepostOrderAndName]: antepostOrderAndNameError,
  [ApiErrors.antepostHasBets]: antepostHasBets,
  [ApiErrors.antepostNoBets]: antepostNoBets,
  [ApiErrors.errExpired]: onErrorExpired,
  [ApiErrors.hasAteposts]: unableToDeteleHasAnteposts,
  [ApiErrors.eventExist]: eventAlreadyExist,
  [ApiErrors.oddMustBeGt1]: oddMustBeGt1,
  [ApiErrors.dateinThePast]: dateInPast,
  [ApiErrors.antepostNameMustBeUnique]: antepostNameMustBeUnique,
  [ApiErrors.favoriteNoLocation]: favoriteNoLocation,
  [ApiErrors.cancelLiveTimeOut]: cancelLiveTimeOut,
};
