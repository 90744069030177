import { SportCode } from './modules/sport';

export const APP_NAME = 'Backoffice';
export const LONG_DATE_TIME = 'MMM D, YYYY HH:mm';
export const SHORT_DATE_TIME = 'ddd DD, MMM HH:mm';
export const DATE = 'YYYY-MM-DD';
export const TIME = 'HH:mm';
export const TIME_SECONDS = 'HH:mm:ss';
export const EUROPEAN_FULL_DATE_TIME = 'DD/MM/YYYY HH:mm:ss';
export const EUROPEAN_DATE_TIME = 'DD/MM/YYYY HH:mm';
export const FULL_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
export const NO_VALUE = '—';
export const DEFAULT_LIMIT = 100;
export const CONFIRMED = true;
export const SPORTS_WITH_LIVE_MARKETS = [
  SportCode.FOOTBALL,
  SportCode.BASEBALL,
  SportCode.HANDBALL,
  SportCode.HOCKEY,
  SportCode.VOLEYBALL,
  SportCode.TENNIS,
  SportCode.TABLE_TENNIS,
  SportCode.AMERICAN_FOOTBALL,
  SportCode.FUTSAL,
  SportCode.BASKETBALL,
];
export const SHORT_DATE = 'DD.MM HH:mm';
export const ONLY_APPROVAL_KEY = 'only_approval';
export const ONLY_WITHDRAWAL_KEY = 'only_withdrawal';
