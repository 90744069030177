import { api } from '@/lib/api';
import { IResolveSlipOddsPayload } from './singleSlip.types';

const getMillionSlips = () => {
  return api.get('slip/slip/million-tickets/', {
    cache: {
      maxAge: 0,
    },
  });
};

const getSlipById = (slipId: string) => {
  return api.post('/slip/slip/filter/', { short_uuid: slipId });
};

const approveMillionSlip = (slipId: string) => api.post(`slip/slip/${slipId}/approve-payout/`);

const denySingleSlipPayout = (slipId: string) => api.post(`slip/slip/${slipId}/deny-payout/`);

const resetSlipOdd = (oddId: string) => api.put(`slip/odds/${oddId}/reset-odd-by-uuid/`);

const resolveOdds = (resolveOddsPayload: IResolveSlipOddsPayload) => {
  return api.put(`slip/odds/settle-odds-by-uuid/`, resolveOddsPayload);
};

const getPayoutLimit = () => api.get('slip/slip/get-n-value/');
const setPayoutLimit = (value: number) => api.post('slip/slip/set-n-value/', { value });

const getDeniedPayoutTicketNumber = () => api.get('slip/slip/million-tickets-count/');

export const singleSlipRepo = {
  getMillionSlips,
  approveMillionSlip,
  getSlipById,
  denySingleSlipPayout,
  resetSlipOdd,
  resolveOdds,
  getPayoutLimit,
  setPayoutLimit,
  getDeniedPayoutTicketNumber,
};
