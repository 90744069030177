import { DefaultOptions } from './filter.types';
import { i18n } from '@/ui/locales/setupLocale';

export const SEARCH_BAR_MAX_HEIGHT = '175px';
export const DEFAULT_FILTER_OPTIONS: DefaultOptions = {
  span: 4,
};

export const IS_CONFIRMED_SELECT_OPTIONS = [
  { value: '', label: i18n.t('selectOptions.all') },
  { value: 'true', label: i18n.t('selectOptions.confirmed') },
  { value: 'false', label: i18n.t('selectOptions.unconfirmed') },
];

export const PROVIDER_SELECT = [
  { value: '', label: i18n.t('selectOptions.all') },
  { value: 'betradar', label: i18n.t('selectOptions.betradar') },
  { value: 'betgenius', label: i18n.t('selectOptions.betgenius') },
];

export const IS_FAVORITE_SELECT_OPTIONS = [
  { value: '', label: i18n.t('selectOptions.all') },
  { value: 'true', label: i18n.t('selectOptions.favorites') },
  { value: 'false', label: i18n.t('selectOptions.nonFavorites') },
];

export const CAN_UNCONFIRMED_SELECT_OPTIONS = [
  { value: '', label: i18n.t('selectOptions.all') },
  { value: 'true', label: i18n.t('selectOptions.canBeUnconfirmed') },
  { value: 'false', label: i18n.t('selectOptions.cantBeUnconfirmed') },
];

export const HAS_BETRADAR_IDS_SELECT_OPTIONS = [
  { value: '', label: i18n.t('selectOptions.all') },
  { value: 'true', label: i18n.t('selectOptions.hasBetradarId') },
  { value: 'false', label: i18n.t('selectOptions.noBetradarId') },
];
export const SELECT_TAG_TYPE = [
  { value: 'true', label: i18n.t('games.mobileTag') },
  { value: 'false', label: i18n.t('games.desktopTag') },
];
