var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('div',{class:_vm.$style.topBarContainer},[_c('div',{class:_vm.$style.logoContainer},[_c('span',[_vm._v(_vm._s(_vm.appVersion))]),_c('img',{attrs:{"src":require("@/assets/logo.svg")}}),_c('ws-status')],1),_c('ul',{class:_vm.$style.navItems},_vm._l((_vm.menuItems),function(link){return _c('router-link',{key:link,attrs:{"to":link},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && _vm.$style.active]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.getRouteName(link)))])])]}}],null,true)})}),1),_c('div',{class:_vm.$style.avatar},[_c('a-dropdown',[_c('a-avatar',{attrs:{"icon":"user"}}),_c('a-menu',{attrs:{"slot":"overlay","theme":"dark"},slot:"overlay"},[_c('a-menu-item',{on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t('appHeader.logOut'))+" ")])],1)],1)],1)]),(!_vm.isApprovalOnlyMode)?_c('div',{class:_vm.$style.subNav},[_c('div',{class:[_vm.$style.filterToggle, _vm.hasActiveFilter && _vm.$style.filterActive]},[(_vm.isFilterConfigured)?_c('div',{class:_vm.$style.toggleButton,on:{"click":_vm.toggleFilter}},[_c('a-icon',{attrs:{"type":"filter"}}),_vm._v(" "+_vm._s(_vm.searchLabel)+" ")],1):_vm._e()]),_c('ul',{class:_vm.$style.navItems},_vm._l((_vm.childrenRoutes),function(child){return _c('router-link',{key:child.path,attrs:{"to":child.path},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && _vm.$style.active]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.meta.title)+" "),(route.meta.title.toLowerCase() == 'single tickets')?_c('a-badge',{class:_vm.$style.badge,attrs:{"count":_vm.millionTicketsCount,"number-style":{
                boxShadow: 'none',
              }}}):_vm._e()],1)])]}}],null,true)})}),1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }