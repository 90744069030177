export enum AvailableRoutes {
  login = '/login',
  createOffer = '/create-offer',
  eventManagment = '/event-managment',
  eventManagmentDual = '/event-managment/dual',
  antepostManagment = '/event-managment/antepost',
  playerEvents = '/event-managment/players',
  live = '/live',
  liveEvents = '/live/events',
  liveTickets = '/live/tickets',
  liveNonBooked = '/live/non-booked',
  prematch = '/prematch',
  prematchDual = '/prematch/dual',
  prematchPlayer = '/prematch/player',
  prematchAntepost = '/prematch/antepost',
  config = '/config',
  approvalTemplates = '/config/approval-templates',
  approvalLiveTemplates = '/config/approval-live-templates',
  marketsTemplates = '/config/markets-templates',
  competitions = '/config/competitions',
  competitors = '/config/competitors',
  outcomes = '/config/outcomes',
  antepostConfig = '/config/antepost',
  eventCodes = '/config/event-codes',
  pendingTime = '/config/pending-time',
  notFound = '/not-found',
  games = '/games',
  gamesOrder = '/games/games-order',
  approval = '/approval',
  slipApproval = '/approval/slip-approval',
  settlement = '/settlement',
  manualSettlement = '/settlement/manual',
  playerSettlement = '/settlement/player',
  livePlayerSettlement = '/settlement/live-player',
  antepostSettlement = '/settlement/antepost',
  settlementEventCtrProvider = '/settlement/ctrl-provider',
  slip = '/slip',
  allTickets = '/slip/all-tickets',
  millionTickets = '/slip/million-tickets',
  gamesTags = '/games/games-tags',
  slotRace = '/games/slot-race',
  mara = '/mara',
  userManagement = '/mara/user-management',
  webContentManagement = '/mara/content-management',
  promotionsManagement = '/mara/promotions-management',
  listConfig = '/config/list',
  transactionReporting = '/mara/transaction-reporting',
  dashboard = '/mara/dashboard',
  payoutLimit = '/config/payout-limit',
  withdrawValidation = '/withdraw-validation',
  requests = '/withdraw-validation/requests',
  withdrawTransactions = '/withdraw-validation/transactions',
  withdrawSettings = '/withdraw-validation/settings',
}
