import axios, { AxiosError } from 'axios';
import NProgress from 'nprogress';
import { setupCache } from 'axios-cache-adapter';

import { getToken, onLogout } from '@/modules/auth/authToken';
import { errorMessage } from './apiErrorMessages';
import { RequestManager } from './requestManager';

NProgress.configure({ showSpinner: false });

const cache = setupCache({
  maxAge: 1 * 60 * 1000, // set default cache to be one minute
});

export const api = axios.create({
  adapter: cache.adapter,
  baseURL: process.env.VUE_APP_API_PATH,
  responseType: 'json',
});

export const requestManager = new RequestManager(api);

export type ApiResponse<T> = Promise<T>;

const errorInterceptors = (error: AxiosError) => {
  NProgress.done();
  if (!error.response) return Promise.reject(error);

  const code = error.response.status as keyof typeof errorMessage;
  const errorType = error.response.data?.error || error.response.data?.message;
  const errorHandler = errorMessage[errorType] || errorMessage[code];
  const notify = errorHandler || errorMessage.genericError;
  notify(error.response?.data?.data);

  if (error.response.status === 401 && !window.location.href.includes('login')) onLogout();
  return Promise.reject(error);
};

api.interceptors.response.use(res => {
  NProgress.done();
  return res.data;
}, errorInterceptors);

api.interceptors.request.use(config => {
  NProgress.start();
  config.headers['Authorization'] = getToken();
  return config;
});
