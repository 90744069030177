import Vue from 'vue';
import Vuex from 'vuex';
import { config } from 'vuex-module-decorators';

config.rawError = true;

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: false,
});

export default store;
