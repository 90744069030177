import to from 'await-to-js';

import { LoginRequest, User } from './auth.types';
import { authRepo } from './auth.repo';
import {
  saveToken,
  onLogout,
  getToken,
  saveRole,
  getRole,
  getIsAdmin,
  getUserId,
} from './authToken';

const TOKEN_KEY = 'token';
const USER_PERMISSIONS = 'userPermissions';

class AuthService {
  fullName(user: User | null | undefined) {
    if (!user) return '';
    return `${user.first_name} ${user.last_name}`;
  }

  getToken() {
    return getToken();
  }

  getRole() {
    return getRole();
  }

  getIsAdmin() {
    return getIsAdmin();
  }

  getUserId() {
    return getUserId();
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem(TOKEN_KEY) && !!localStorage.getItem(USER_PERMISSIONS);
  }

  async login({ username, password }: LoginRequest) {
    const [error, response] = await to(authRepo.login(username, password));
    if (response && response.token) {
      saveToken(response.token);
      saveRole({
        role: response.user.role,
        isAdmin: response.user.is_admin,
        userId: response.user.id,
      });
      return Promise.resolve(response);
    }

    return Promise.reject(error);
  }

  logout() {
    onLogout();
  }
}

export const authService = new AuthService();
