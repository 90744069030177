import { api } from '@/lib/api';
import { Sport, Sports } from './sport.types';

const getSportsWithCompetitions = (): Promise<Sport[]> => {
  return api.get('odds/sports_competitions/', {
    cache: {
      maxAge: 0,
    },
  });
};

const getSportsWithCompetitors = (): Promise<Sport[]> => api.get('odds/sports/competitors/');

const fetchSports = (): Promise<Sports[]> => api.get('odds/sports/');

const getAntepost = (): Promise<Sports[]> => api.get('odds/sports/competitions-antepost-markets/');

export const sportRepo = {
  getSportsWithCompetitions,
  fetchSports,
  getSportsWithCompetitors,
  getAntepost,
};
