import { Route } from 'vue-router';

import { APP_NAME } from '@/constants';

export const setPageTitle = (to: Route) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  document.title = nearestWithTitle ? `${APP_NAME} - ${nearestWithTitle.meta.title}` : APP_NAME;
};
