import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en.json';

const messages = { en };

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en', // default locale
  messages, // set locale messages
  silentTranslationWarn: true,
});
