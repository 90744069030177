
import Vue from 'vue';
import Component from 'vue-class-component';

import { AuthStore, ROLES_VIEW, Roles } from '@/modules/auth/';
import { AvailableRoutes } from '@/ui/types/route.types';
import { FilterStore } from '@/modules/filter/filter.store';
import { findRoute } from '@/lib/findRoute';
import { ONLY_APPROVAL_KEY, ONLY_WITHDRAWAL_KEY } from '@/constants';
import WsStatus from '../WsStatus/WsStatus.vue';
import { SingleSlipStore } from '@/modules/singleSlip/singleSlip.store';

@Component({ name: 'AppHeader', components: { WsStatus } })
export default class AppHeader extends Vue {
  get backofficeRoleId() {
    return AuthStore.backofficeRoleId;
  }

  get menuItems() {
    if (localStorage.getItem(ONLY_APPROVAL_KEY)) return [AvailableRoutes.approval];
    if (localStorage.getItem(ONLY_WITHDRAWAL_KEY)) return [AvailableRoutes.withdrawValidation];
    return ROLES_VIEW[this.backofficeRoleId as Roles];
  }

  get isFilterConfigured() {
    return FilterStore.isFilterConfigured;
  }

  get hasSubNav() {
    return true;
  }

  get searchOpen() {
    return FilterStore.isOpen;
  }

  get hasActiveFilter() {
    return FilterStore.hasActiveFilter;
  }

  get fullName() {
    return AuthStore.fullName;
  }

  get searchLabel() {
    return this.searchOpen ? 'Close Search' : 'Open Search';
  }

  get childrenRoutes() {
    const mainRoute = this.$route.matched[1];
    if (!mainRoute || !this.$router.options.routes) {
      return [];
    }
    const { routes } = this.$router.options;
    const parentRoute = findRoute(routes, mainRoute.path) as any;

    if (parentRoute && parentRoute.children) {
      return parentRoute.children;
    }
    return [];
  }

  get appVersion() {
    return `v${process.env.VERSION}`;
  }

  get isApprovalOnlyMode() {
    return !!localStorage.getItem(ONLY_APPROVAL_KEY);
  }

  get isWithdrawalOnlyMode() {
    return !!localStorage.getItem(ONLY_WITHDRAWAL_KEY);
  }

  get millionTicketsCount() {
    return SingleSlipStore.deniedPayoutTicketNumber;
  }

  get offset() {
    return this.millionTicketsCount;
  }

  logout() {
    AuthStore.logout();
  }

  toggleFilter() {
    FilterStore.toggle();
  }

  getRouteName(link: string) {
    const { routes } = this.$router.options;
    if (!routes) return '';
    const route = findRoute(routes, link) as any;
    return route?.meta.title;
  }
}
