import { SLIP_STATUS_COLORS } from './slip.constants';
import { slipRepo } from './slip.repo';
import { ISlipStatusConfig, Slip, SlipStatus } from './slip.types';

class SlipService {
  getSlips(params: any) {
    // eslint-disable-next-line prefer-const
    let { limit, offset, ordering, ...rest } = params;
    if (ordering.includes('slip_type')) {
      ordering = ordering.replace('slip_type', 'slip_type_sort');
    } else if (ordering.includes('status')) {
      ordering = ordering.replace('status', 'status_sort');
    }

    return slipRepo.getSlips(this.buildRequest(rest), { ordering, limit, offset });
  }

  dateFilter(dateTime: { date: string; hour: number; minute: number; second: number }) {
    if (!dateTime.date) return;
    const { date, hour, minute, second } = dateTime;
    const getValue = (value: number) => String(value ?? 0).padStart(2, '0');
    return `${date} ${getValue(hour)}:${getValue(minute)}:${getValue(second)}`;
  }

  buildRequest(params: any) {
    const { from, to, ...rest } = params;
    const onlyFilled = Object.keys(rest).reduce((acc, key) => {
      if (rest[key]) {
        return { ...acc, [key]: rest[key] };
      }
      return acc;
    }, {});

    return {
      ...onlyFilled,
      created_at_from: this.dateFilter(from),
      created_at_to: this.dateFilter(to),
    };
  }

  getUsernameOrPlace(slip: Slip) {
    if (slip.slip_env === 'landbase') return slip.betting_place;
    return slip.username;
  }

  getStatusColor(status: SlipStatus): ISlipStatusConfig {
    return (SLIP_STATUS_COLORS as any)[status] || { text: '', color: '' };
  }

  cancelSlip(slipId: string) {
    return slipRepo.cancelSlip(slipId);
  }

  mapSlipFromFeed(slip: any) {
    return {
      created_at: slip.c_at,
      max_winning_amount: slip.m_w,
      max_odds_value: slip.max_o,
      min_odds_value: slip.min_o,
      note: slip.n,
      number_of_matches: slip.n_o_m,
      amount: slip.p,
      player_risk: slip.r,
      status: slip.s,
      short_uuid: slip.s_id,
      slip_type: slip.s_t,
      username: slip.u,
      id: slip.id,
      addedViaWs: true,
      betting_place: slip.b_p,
      slip_env: slip.s_e,
    };
  }

  mapSlipStatusUpdate(slipData: any) {
    return {
      status: slipData.s,
      max_winning_amount: slipData.mx_w_a,
      min_winning_amount: slipData.mn_w_a,
      actual_winning_amount: slipData.a_w_a,
      max_bonus_amount: slipData.mx_b_a,
      min_bonus_amount: slipData.mn_b_a,
      max_total_amount: slipData.mx_t_a,
      max_odds_value: slipData.mx_o_v,
      min_odds_value: slipData.mn_o_v,
      short_uuid: slipData.s_uuid,
    };
  }
}

export const slipService = new SlipService();
