import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import to from 'await-to-js';

import { AuthState, LoginRequest, User } from './auth.types';
import { authService } from './auth.service';
import store from '@/store';
import { i18n } from '@/ui/locales/setupLocale';

// TODO Refetch user on page reload

@Module({ dynamic: true, store, name: 'auth', namespaced: true })
class Auth extends VuexModule implements AuthState {
  user: User | null = null;
  accessToken: string | null = authService.getToken();
  roleId!: number;
  isAdmin = false;

  get fullName() {
    return authService.fullName(this.user);
  }

  get backofficeRoleId() {
    return this.roleId || authService.getRole().role;
  }

  get isBackofficeAdmin() {
    return this.isAdmin || authService.getIsAdmin().isAdmin;
  }

  //1 is temp value cuz for some reason now we have no user data
  get userId() {
    return this.user?.id || authService.getUserId();
  }

  @Mutation
  setUser(user: User) {
    this.user = user;
  }

  @Mutation
  setAccessToken(token: string) {
    this.accessToken = token;
  }

  @Mutation
  setUserRole({ roleId, isAdmin }: { roleId: number; isAdmin: boolean }) {
    this.roleId = roleId;
    this.isAdmin = isAdmin;
  }

  @Mutation
  resetStore() {
    this.user = null;
    this.accessToken = null;
  }

  @Action
  async login(loginRequest: LoginRequest) {
    const [error, response] = await to(authService.login(loginRequest));
    if (response && 'user' in response) {
      const { user, token } = response;
      if (!user.role) {
        return Promise.reject(i18n.t('login.roleIsNotValid'));
      }
      this.setUser(user);
      this.setAccessToken(token);
      this.setUserRole({ roleId: user.role, isAdmin: user.is_admin });
      return Promise.resolve(response);
    }
    return Promise.reject(error as any);
  }

  @Action
  logout() {
    authService.logout();
  }
}

export const AuthStore = getModule(Auth);
