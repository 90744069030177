import { OddStatus } from '../slip';
import { IResolveSlipOddsPayload } from './singleSlip.types';

export const RESOLVE_SLIP_ODDS_ACCESSORS: (keyof IResolveSlipOddsPayload)[] = [
  'LOST',
  'WON',
  'ODD_ONE',
];

export const RESOLVE_ACTIONS_ODD_STATUS_MAP = {
  WON: OddStatus.PASSED,
  LOST: OddStatus.FAILED,
  ODD_ONE: OddStatus.CANCELLED,
};
