import { api } from '@/lib/api';

const getSlips = (params: any, urlParams: any) => {
  return api.post('/slip/slip/filter/', params, { params: urlParams });
};

const cancelSlip = (slipId: string) => {
  return api.put(`slip/slip/${slipId}/roll-back/`, { source: 'BO-SET' });
};

export const slipRepo = { getSlips, cancelSlip };
