export type UserType = 'backoffice_user';

import { AvailableRoutes } from '@/ui/types/route.types';
export interface User {
  id: number;
  created_at: string;
  modified_at: null;
  username: string;
  first_name: string;
  last_name: string;
  is_active: boolean | null;
  user_type: UserType;
  role: number;
  is_admin: boolean;
  approval_id: null | number;
}

export interface LoginResponse {
  token: string;
  user: User;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface AuthState {
  accessToken: string | null;
  user: User | null;
}

export enum Roles {
  config = 1,
  prematch = 2,
  live = 3,
  settlement = 4,
  mara = 5,
  all = 6,
  withdrawal = 8,
}

export const ROLES_VIEW = {
  [Roles.config]: [
    AvailableRoutes.createOffer,
    AvailableRoutes.eventManagment,
    AvailableRoutes.config,
  ],
  [Roles.prematch]: [AvailableRoutes.prematch, AvailableRoutes.approval, AvailableRoutes.slip],
  [Roles.live]: [AvailableRoutes.live, AvailableRoutes.slip, AvailableRoutes.config],
  [Roles.settlement]: [AvailableRoutes.settlement, AvailableRoutes.slip],
  [Roles.mara]: [AvailableRoutes.mara],
  [Roles.withdrawal]: [AvailableRoutes.withdrawValidation],
  [Roles.all]: [
    AvailableRoutes.createOffer,
    AvailableRoutes.eventManagment,
    AvailableRoutes.prematch,
    AvailableRoutes.live,
    AvailableRoutes.config,
    AvailableRoutes.games,
    AvailableRoutes.settlement,
    AvailableRoutes.slip,
    AvailableRoutes.mara,
    AvailableRoutes.withdrawValidation,
  ],
};
