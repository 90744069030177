import { i18n } from '@/ui/locales/setupLocale';

export default (value: string) => {
  if (!value) return i18n.t('common.notAvailable');
  if (value === 'DBP') return value;
  const number = parseFloat(value);
  if (!number) return '0.00';
  if (number <= 1) return '-';
  return number.toFixed(2);
};
