import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { MainLayout, PublicLayout } from '@/ui/layouts';
import LoginPage from '@/ui/pages/login';
import NotFound from '@/ui/pages/not-found';
import { setPageTitle } from '@/lib/setPageTitle';
import { AvailableRoutes } from './types/route.types';
import { i18n } from '@/ui/locales/setupLocale';
import { authService, AuthStore, Roles, ROLES_VIEW } from '@/modules/auth/';
import { requestManager } from '@/lib/api';
import isEmpty from 'lodash/isEmpty';

import { ONLY_APPROVAL_KEY, ONLY_WITHDRAWAL_KEY } from '@/constants';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: AvailableRoutes.login,
  },
  {
    path: '*',
    component: NotFound,
  },
  {
    path: AvailableRoutes.login,
    component: PublicLayout,
    children: [
      {
        path: '',
        component: LoginPage,
        meta: {
          title: i18n.t('routes.login'),
        },
      },
    ],
  },
  {
    path: '',
    component: MainLayout,
    children: [
      {
        path: AvailableRoutes.createOffer,
        component: () => import(/* webpackChunkName: "create-offer" */ '@/ui/pages/create-offer'),
        meta: {
          title: i18n.t('routes.createOffer'),
          role: [1, 6],
        },
      },
      {
        path: AvailableRoutes.eventManagment,
        redirect: AvailableRoutes.eventManagmentDual,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: i18n.t('routes.eventManagment'),
          role: [1, 6],
        },
        children: [
          {
            path: AvailableRoutes.eventManagmentDual,
            component: () =>
              import(
                /* webpackChunkName: "event-management-dual" */ '@/ui/pages/event-managment/EventsPage.vue'
              ),
            meta: {
              title: i18n.t('routes.dual'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.playerEvents,
            component: () =>
              import(
                /* webpackChunkName: "event-management-players" */ '@/ui/pages/player-events/PlayerEventsPage.vue'
              ),
            meta: {
              title: i18n.t('routes.player'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.antepostManagment,
            component: () =>
              import(
                /* webpackChunkName: "event-management-antepost" */ '@/ui/pages/antepost-managment'
              ),
            meta: {
              title: i18n.t('routes.antepost'),
              role: [1, 6],
            },
          },
        ],
      },
      {
        path: AvailableRoutes.prematch,
        redirect: AvailableRoutes.prematchDual,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: i18n.t('routes.prematch'),
          role: [2, 6],
        },
        children: [
          {
            path: AvailableRoutes.prematchDual,
            component: () => import(/* webpackChunkName: "prematch-dual" */ '@/ui/pages/prematch/'),
            meta: {
              title: i18n.t('routes.prematch'),
              role: [2, 6],
            },
          },
          {
            path: AvailableRoutes.prematchPlayer,
            component: () =>
              import(/* webpackChunkName: "prematch-player" */ '@/ui/pages/prematch-player/'),
            meta: {
              title: i18n.t('routes.player'),
              role: [2, 6],
            },
          },
          {
            path: AvailableRoutes.prematchAntepost,
            component: () =>
              import(/* webpackChunkName: "prematch-player" */ '@/ui/pages/antepost-prematch/'),
            meta: {
              title: i18n.t('routes.antepost'),
              role: [2, 6],
            },
          },
        ],
      },
      {
        path: AvailableRoutes.live,
        redirect: AvailableRoutes.liveEvents,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: i18n.t('routes.live'),
          role: [3, 6],
        },
        children: [
          {
            path: AvailableRoutes.liveEvents,
            component: () =>
              import(/* webpackChunkName: "event-managment-live" */ '@/ui/pages/live/'),
            meta: {
              title: i18n.t('routes.liveEvents'),
              role: [3, 6],
            },
          },
          {
            path: AvailableRoutes.liveTickets,
            component: () =>
              import(/* webpackChunkName: "live-tickets" */ '@/ui/pages/live-tickets/'),
            meta: {
              title: i18n.t('routes.liveTickets'),
              role: [3, 6],
            },
          },
          {
            path: AvailableRoutes.liveNonBooked,
            component: () =>
              import(/* webpackChunkName: "live-non-booked" */ '@/ui/pages/live-non-booked/'),
            meta: {
              title: i18n.t('routes.liveNonBooked'),
              role: [3, 6],
            },
          },
        ],
      },

      {
        path: AvailableRoutes.games,
        redirect: AvailableRoutes.gamesOrder,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: i18n.t('games.games'),
          role: [6],
        },
        children: [
          {
            path: AvailableRoutes.gamesOrder,
            component: () =>
              import(
                /* webpackChunkName: "games-order" */ '@/ui/pages/games-managment/GamesOrder.vue'
              ),
            meta: {
              title: i18n.t('games.gamesOrder'),
              role: [6],
            },
          },
          {
            path: AvailableRoutes.gamesTags,
            component: () =>
              import(
                /* webpackChunkName: "games-tags" */ '@/ui/pages/games-managment/GamesTags.vue'
              ),
            meta: {
              title: i18n.t('games.gamesTags'),
              role: [6],
            },
          },
          {
            path: AvailableRoutes.slotRace,
            component: () =>
              import(
                /* webpackChunkName: "slot-race" */ '@/ui/pages/games-managment/SlotRacePage.vue'
              ),
            meta: {
              title: i18n.t('games.slotRace'),
              role: [6],
            },
          },
        ],
      },
      {
        path: AvailableRoutes.settlement,
        redirect: AvailableRoutes.manualSettlement,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: i18n.t('settlement.settlement'),
          role: [4, 6],
        },
        children: [
          {
            path: AvailableRoutes.manualSettlement,
            component: () =>
              import(
                /* webpackChunkName: "settlement-dual" */ '@/ui/pages/settlement/SettlementPage.vue'
              ),
            meta: {
              title: i18n.t('settlement.manualSettlement'),
              role: [4, 6],
            },
          },
          {
            path: AvailableRoutes.playerSettlement,
            component: () =>
              import(
                /* webpackChunkName: "settlement-player" */ '@/ui/pages/player-settlement/PlayerSettlementPage.vue'
              ),
            meta: {
              title: i18n.t('routes.playerSettlement'),
              role: [4, 6],
            },
          },
          {
            path: AvailableRoutes.livePlayerSettlement,
            component: () =>
              import(
                /* webpackChunkName: "settlement-live-player" */ '@/ui/pages/player-live-settlement/PlayerLiveSettlementPage.vue'
              ),
            meta: {
              title: i18n.t('routes.playerLiveSettlement'),
              role: [4, 6],
            },
          },
          {
            path: AvailableRoutes.antepostSettlement,
            component: () =>
              import(
                /* webpackChunkName: "settlement-antepost" */ '@/ui/pages/settlement-antepost/SettlementAntepostPage.vue'
              ),
            meta: {
              title: i18n.t('routes.antepostSettlement'),
              role: [4, 6],
            },
          },
          {
            path: AvailableRoutes.settlementEventCtrProvider,
            component: () =>
              import(
                /* webpackChunkName: "settlement-control-provider" */ '@/ui/pages/settlement-event-with-ctrl-provider/SettlementControlProvider.vue'
              ),
            meta: {
              title: i18n.t('routes.settlementControlProvider'),
              role: [4, 6],
            },
          },
        ],
      },
      {
        path: AvailableRoutes.slip,
        redirect: AvailableRoutes.allTickets,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: i18n.t('routes.slip'),
          role: [2, 3, 4, 6],
        },
        children: [
          {
            path: AvailableRoutes.allTickets,
            component: () => import(/* webpackChunkName: "slip-all-tickets" */ '@/ui/pages/slip/'),
            meta: {
              title: i18n.t('routes.slip'),
              role: [2, 3, 4, 6],
            },
          },
          {
            path: AvailableRoutes.millionTickets,
            component: () =>
              import(
                /* webpackChunkName: "slip-single-tickets" */ '@/ui/pages/single-tickets/SingleTicketsPage.vue'
              ),
            meta: {
              title: i18n.t('routes.singleTickets'),
              role: [4, 6],
            },
          },
        ],
      },
      {
        path: AvailableRoutes.config,

        redirect: AvailableRoutes.approvalTemplates,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: i18n.t('routes.config'),
          role: [1, 6],
        },
        children: [
          {
            path: AvailableRoutes.approvalTemplates,
            component: () =>
              import(/* webpackChunkName: "aproval-templates" */ '@/ui/pages/approval-templates/'),
            meta: {
              title: i18n.t('routes.approvalTemplates'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.marketsTemplates,
            component: () =>
              import(/* webpackChunkName: "markets-templates" */ '@/ui/pages/markets-templates/'),
            meta: {
              title: i18n.t('routes.marketsTemplates'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.outcomes,
            component: () =>
              import(/* webpackChunkName: "outcomes-templates" */ '@/ui/pages/outcomes-templates'),
            meta: {
              title: i18n.t('routes.outcomesTemplates'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.competitions,
            component: () =>
              import(/* webpackChunkName: "competitions" */ '@/ui/pages/competitions/'),
            meta: {
              title: i18n.t('routes.competition'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.competitors,
            component: () =>
              import(/* webpackChunkName: "competitors" */ '@/ui/pages/competitors/'),
            meta: {
              title: i18n.t('routes.competitors'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.antepostConfig,
            component: () =>
              import(/* webpackChunkName: "antepost-config" */ '@/ui/pages/antepost-config/'),
            meta: {
              title: i18n.t('routes.antepost'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.eventCodes,
            component: () =>
              import(/* webpackChunkName: "event-codes" */ '@/ui/pages/event-codes/'),
            meta: {
              title: i18n.t('routes.eventCodes'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.listConfig,
            component: () =>
              import(/* webpackChunkName: "list-config" */ '@/ui/pages/list-config/'),
            meta: {
              title: i18n.t('routes.listConfig'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.pendingTime,
            component: () =>
              import(/* webpackChunkName: "pending-time" */ '@/ui/pages/pending-time/'),
            meta: {
              title: i18n.t('routes.pendingTime'),
              role: [1, 6],
            },
          },
          {
            path: AvailableRoutes.payoutLimit,
            component: () =>
              import(/* webpackChunkName: "payout-limit" */ '@/ui/pages/payout-limit/'),
            meta: {
              title: i18n.t('routes.payoutLimit'),
              role: [1, 6],
            },
          },
        ],
      },
      {
        path: AvailableRoutes.mara,
        redirect: AvailableRoutes.userManagement,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: i18n.t('routes.mara'),
          role: [5, 6],
        },
        children: [
          {
            path: AvailableRoutes.userManagement,
            component: () =>
              import(/* webpackChunkName: "user-management" */ '@/ui/pages/user-management'),
            meta: {
              title: i18n.t('routes.userManagement'),
              role: [5, 6],
            },
          },
          {
            path: AvailableRoutes.webContentManagement,
            component: () =>
              import(
                /* webpackChunkName: "web-content-management" */ '@/ui/pages/web-content-management'
              ),
            meta: {
              title: i18n.t('routes.webContentManagement'),
              role: [5, 6],
            },
          },
          // {
          //   path: AvailableRoutes.promotionsManagement,
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "promotions-management" */ '@/ui/pages/promotions-management'
          //     ),
          //   meta: {
          //     title: i18n.t('routes.promotionsManagement'),
          //     role: [5, 6],
          //   },
          // },
          {
            path: AvailableRoutes.transactionReporting,
            component: () =>
              import(
                /* webpackChunkName: "transaction-reporting" */ '@/ui/pages/transaction-reporting'
              ),
            meta: {
              title: i18n.t('routes.transactionReporting'),
              role: [5, 6],
            },
          },
          {
            path: AvailableRoutes.dashboard,
            component: () => import(/* webpackChunkName: "dashboard" */ '@/ui/pages/dashboard'),
            meta: {
              title: i18n.t('routes.dashboard'),
              role: [5, 6],
            },
          },
        ],
      },
    ],
  },
];

const onlyWithdrwal: Array<RouteConfig> = [
  { path: '/', redirect: AvailableRoutes.login },
  { path: '*', component: NotFound },
  {
    path: AvailableRoutes.login,
    component: PublicLayout,
    children: [
      {
        path: '',
        component: LoginPage,
        meta: {
          title: i18n.t('routes.login'),
        },
      },
    ],
  },
  {
    path: '',
    component: MainLayout,
    children: [
      {
        path: AvailableRoutes.withdrawValidation,
        redirect: AvailableRoutes.requests,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: i18n.t('routes.withdrawValidation'),
          role: [6, 8],
        },

        children: [
          {
            path: AvailableRoutes.requests,
            component: () =>
              import(
                /* webpackChunkName: "withdraw-validation" */ '@/ui/pages/withdraw-validation'
              ),
            meta: {
              title: i18n.t('routes.withdrawValidation'),
              role: [6, 8],
            },
          },
          {
            path: AvailableRoutes.withdrawTransactions,
            component: () =>
              import(
                /* webpackChunkName: "withdraw-transactions" */ '@/ui/pages/withdraw-transactions'
              ),
            meta: {
              title: i18n.t('routes.withdrawDetails'),
              role: [6, 8],
            },
          },
          {
            path: AvailableRoutes.withdrawSettings,
            component: () =>
              import(/* webpackChunkName: "withdraw-settings" */ '@/ui/pages/withdraw-settings'),
            meta: {
              title: i18n.t('routes.withdrawSettings'),
              role: [6, 8],
            },
          },
        ],
      },
    ],
  },
];

const onlyApproval: Array<RouteConfig> = [
  { path: '/', redirect: AvailableRoutes.login },
  { path: '*', component: NotFound },
  {
    path: AvailableRoutes.login,
    component: PublicLayout,
    children: [
      {
        path: '',
        component: LoginPage,
        meta: {
          title: i18n.t('routes.login'),
        },
      },
    ],
  },
  {
    path: '',
    component: MainLayout,
    children: [
      {
        path: AvailableRoutes.approval,
        redirect: AvailableRoutes.slipApproval,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: i18n.t('slipApproval.approval'),
          role: [6],
        },
        children: [
          {
            path: AvailableRoutes.slipApproval,
            component: () =>
              import(
                /* webpackChunkName: "slip-approval" */ '@/ui/pages/slip-approval/SlipApprovalPage.vue'
              ),
            meta: {
              title: i18n.t('slipApproval.approval'),
              role: [6],
            },
          },
        ],
      },
    ],
  },
];

const routesToApply = () => {
  if (localStorage.getItem(ONLY_APPROVAL_KEY)) return onlyApproval;
  if (localStorage.getItem(ONLY_WITHDRAWAL_KEY)) return onlyWithdrwal;
  return routes;
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routesToApply(),
});

router.beforeEach((to, _from, next) => {
  const isLoggedIn = authService.isLoggedIn();
  if (!isLoggedIn && to.path !== AvailableRoutes.login) {
    next(AvailableRoutes.login);
  } else if (to.path === AvailableRoutes.login && isLoggedIn) {
    if (localStorage.getItem(ONLY_APPROVAL_KEY)) {
      next(AvailableRoutes.approval);
    } else if (localStorage.getItem(ONLY_WITHDRAWAL_KEY)) {
      next(AvailableRoutes.withdrawValidation);
    } else {
      next(AvailableRoutes.createOffer);
    }
  } else if (
    isLoggedIn &&
    !isEmpty(to.meta) &&
    !to.meta?.role.includes(AuthStore.backofficeRoleId)
  ) {
    router.replace(ROLES_VIEW[AuthStore.backofficeRoleId as Roles][0]);
    next();
  } else {
    setPageTitle(to);
    requestManager.cancelAll();
    next();
  }
});

export default router;
