import { SlipGroup } from '@/modules/slipApproval';
import VueI18n from 'vue-i18n';

export interface Slip {
  id: string;
  created_at: string;
  amount: number;
  old_amount: null | number;
  approval_reason: null | string;
  user: number;
  slip_groups: SlipGroup[];
  number_of_combinations: number;
  status: SlipStatus;
  max_odds_value: number;
  min_odds_value: number;
  transaction_id: null | number;
  actual_odds_value: number;
  number_of_matches: number;
  approved_by: null | number;
  betting_place_id: null | number;
  short_uuid: string;
  max_winning_amount: number;
  min_winning_amount: number;
  actual_winning_amount: null | number;
  is_system: boolean;
  denial_reason: { errors: any[] };
  canceled_by: null | number;
  cancel_reason: null | string;
  cancel_time: null | string;
  automated_denied: boolean;
  for_approving: boolean;
  slip_env: SlipEnv;
  slip_type: SlipType;
  betting_machine_id: null;
  max_bonus_amount: number;
  min_bonus_amount: number;
  note: null | string;
  player_risk: null | number;
  username: null | string;
  payout_approved: boolean;
  betting_place: string;
}

export enum SlipStatus {
  INIT = '__INIT__',
  PAYED = '__PAYED__',
  RESERVED_FUNDS = '__RESERVED_FUNDS__',
  RELEASED_FUNDS = '__RELEASED_FUNDS__',
  VALID = '__VALID__',
  INVALID = '__INVALID__',
  REFUNDED = '__REFUNDED__',
  APPROVING = '__APPROVING__',
  APPROVED = '__APPROVED__',
  MANUAL_APPROVAL_APPROVED = '__MANUAL_APPROVAL_APPROVED__',
  MANUAL_APPROVAL_DENIED = '__MANUAL_APPROVAL_DENIED__',
  MANUAL_APPROVAL_CHANGED = '__MANUAL_APPROVAL_CHANGED__',
  USER_ACCEPTED_CHANGE = '__USER_ACCEPTED_CHANGE__',
  USER_DENIED_CHANGE = '__USER_DENIED_CHANGE__',
  AUTO_APPROVAL_DENIED = '__AUTO_APPROVAL_DENIED__',
  DENIED = '__DENIED__',
  BACKOFFICE_PAYED_OUT = '__BACKOFFICE_PAYED_OUT__',
  CANCELED = '__CANCELED__',
  PAYED_OUT = '__PAYED_OUT__',
  CASHBACK = '__CASHBACK__',
  CASHOUT = '__CASHOUT__',
  NOT_RESOLVED = '__NOT_RESOLVED__',
  PASSED = '__PASSED__',
  FAILED = '__FAILED__',
}

export enum OddStatus {
  FAILED = '__FAILED__',
  PASSED = '__PASSED__',
  NOT_RESOLVED = '__NOT_RESOLVED__',
  CANCELLED = '__CANCELED__',
  REVERT_BY_PROVIDER = '__REVERT_BY_PROVIDER__',
}

export enum SlipType {
  PREMATCH = 'PREMATCH',
  LIVE = 'LIVE',
  MIX = 'MIX',
  OUTRIGHT = 'OUTRIGHT',
}

export type SlipEnv = 'web' | 'landbase';

export interface ISlipStatusConfig {
  text: VueI18n.TranslateResult;
  winLabelText: VueI18n.TranslateResult;
  bonusLabelText: VueI18n.TranslateResult;
  totalWinLabelText: VueI18n.TranslateResult;
  color: string;
  textColor: string;
}
