interface Common {
  id: number;
  name: string;
  betradarId: string;
}

export interface SportData extends Common {
  locations: LocationData[];
}

export interface FiltersSport extends SportData {
  clientId: string;
  type: 'sport';
  isSelected: boolean;
  isActive: boolean;
  locations: Location[];
}

export interface DefaultSelected {
  sports: number[];
  locations: number[];
  competitions: number[];
}

export interface Location extends LocationData {
  clientId: string;
  type: 'location';
  isSelected: boolean;
  isActive: boolean;
  competitions: Competition[];
  parents: string[];
}
export interface Competition extends CompetitionData {
  clientId: string;
  isSelected: boolean;
  isActive: boolean;
  type: 'competition';
  parents: string[];
}

export interface LocationData extends Common {
  competitions: CompetitionData[];
}

interface CompetitionData extends Common {
  sport: number;
  location: number;
}

export interface SportFilterState {
  sports: SportData[] | null;
  expandedItems: string[] | [];
  isLoading: boolean;
}

export type ItemType = 'sport' | 'location' | 'competition';
export type SportFilterData = SportData | LocationData | CompetitionData;
export type SportFilterItem = FiltersSport | Location | Competition;
export type ToggleMode = 'select' | 'activate';
export type ToggleKey = 'isActive' | 'isSelected';

export const isSport = (item: SportFilterItem): item is FiltersSport => {
  return (item as FiltersSport).locations !== undefined;
};

export const isLocation = (item: SportFilterItem): item is Location => {
  return (item as Location).competitions !== undefined;
};

export const isFilterSport = (object: any): object is FiltersSport => {
  return 'locations' in object;
};

export enum SportSource {
  factory = 'factory',
  betRadar = 'betRadar',
  competitors = 'competitors',
  antepost = 'antepost',
  antepostSettlement = 'antepostSettlement',
}

export interface SelectedSportLocationCompetitionName {
  sport: { name: string; id: number };
  location: { name: string; id: number };
  competition: { name: string; id: number };
}
