var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{class:_vm.$style.form},[_c('img',{attrs:{"src":require("@/assets/logo.svg")}}),_c('a-form',{attrs:{"form":_vm.loginForm},on:{"submit":_vm.onSubmit}},[(_vm.errorMessage)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","showIcon":"","message":_vm.errorMessage}}):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'username',
              { rules: [{ required: true, message: _vm.$t('login.usernameRequiredError') }] } ]),expression:"[\n              'username',\n              { rules: [{ required: true, message: $t('login.usernameRequiredError') }] },\n            ]"}],attrs:{"autoFocus":"","autocomplete":"autocomplete","size":"large","data-test":"username","placeholder":_vm.$t('login.usernamePlaceholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$t('login.passwordRequiredError'),
                    whitespace: true,
                  } ],
              } ]),expression:"[\n              'password',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('login.passwordRequiredError'),\n                    whitespace: true,\n                  },\n                ],\n              },\n            ]"}],attrs:{"size":"large","data-test":"password","autocomplete":"autocomplete","type":"password","placeholder":_vm.$t('login.passwordPlaceholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-button',{class:_vm.$style.loginButton,attrs:{"type":"primary","data-test":"submit","html-type":"submit","loading":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t('login.submit'))+" ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }