
import Vue from 'vue';
import Component from 'vue-class-component';
import { FilterStore } from '@/modules/filter/filter.store';
import { Config, Options, Components } from '@/modules/filter/filter.types';
import debounce from 'lodash/debounce';
import { SEARCH_BAR_MAX_HEIGHT, DEFAULT_FILTER_OPTIONS } from '@/modules/filter/filter.constants';

const components: Components = {
  'input-filter': () => import('./components/InputFilter.vue'),
  'select-filter': () => import('./components/SelectFilter.vue'),
  'date-filter': () => import('./components/DateFilter.vue'),
  'checkbox-filter': () => import('./components/CheckboxFilter.vue'),
  'range-filter': () => import('./components/RangeFilter.vue'),
  'event-state-filter': () => import('./components/EventStateFilter.vue'),
};

@Component({
  components,
})
export default class PageFilter extends Vue {
  private onChangeDebounced = debounce(this.onChange, 550);

  get hasActiveFilter() {
    return !FilterStore.hasActiveFilter;
  }

  get isOpen() {
    return FilterStore.isOpen;
  }

  get maxHeight() {
    return this.isOpen ? SEARCH_BAR_MAX_HEIGHT : '0';
  }

  get config() {
    return FilterStore.config;
  }

  get isLoading() {
    return FilterStore.loading;
  }

  getValue(key: string | string[]) {
    if (Array.isArray(key)) {
      const [first, secound] = key;
      return [FilterStore.value[first], FilterStore.value[secound]];
    }
    return FilterStore.value[key];
  }

  toggle() {
    FilterStore.toggle();
  }

  getFilterOptionValue(filter: Config, key: keyof Options) {
    if (!filter.options || !filter.options[key]) {
      return DEFAULT_FILTER_OPTIONS[key];
    }
    return filter.options[key];
  }

  onChange(data: { key: string; value: any }) {
    FilterStore.onValueChange(data);
  }

  clearAll() {
    FilterStore.clearFilter();
  }

  getKey(key: string | string[]) {
    if (Array.isArray(key)) {
      return key[0] + key[1];
    }
    return key;
  }
}
