import Vue from 'vue';
import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu,
  Row,
  Spin,
  Switch,
  Tooltip,
  notification,
  Select,
  DatePicker,
  Badge,
  Tabs,
  Empty,
  InputNumber,
  Tag,
  Skeleton,
  Modal,
  Popconfirm,
  Radio,
  Slider,
  Comment,
  Popover,
  Drawer,
  Transfer,
  Divider,
  List,
  Upload,
  Pagination,
  Timeline,
} from 'ant-design-vue';
import './antd.less';

export const setupAntd = () => {
  Vue.use(Button);
  Vue.use(Col);
  Vue.use(Row);
  Vue.use(ConfigProvider);
  Vue.use(Form);
  Vue.use(Input);
  Vue.use(Alert);
  Vue.use(Tooltip);
  Vue.use(Icon);
  Vue.use(Switch);
  Vue.use(Checkbox);
  Vue.use(Spin);
  Vue.use(Avatar);
  Vue.use(Dropdown);
  Vue.use(Menu);
  Vue.use(Spin);
  Vue.use(Select);
  Vue.use(DatePicker);
  Vue.use(Badge);
  Vue.use(Tabs);
  Vue.use(Empty);
  Vue.use(InputNumber);
  Vue.use(Tag);
  Vue.use(Skeleton);
  Vue.use(Modal);
  Vue.use(Popconfirm);
  Vue.use(Radio);
  Vue.use(Slider);
  Vue.use(Comment);
  Vue.use(Popover);
  Vue.use(Drawer);
  Vue.use(Transfer);
  Vue.use(Divider);
  Vue.use(List);
  Vue.use(Upload);
  Vue.use(Pagination);
  Vue.use(Timeline);
  Vue.prototype.$notification = notification;
  Vue.prototype.$confirm = Modal.confirm;
  Vue.prototype.$error = Modal.error;
};

declare module 'vue/types/vue' {
  interface Vue {
    $form: Form;
  }
  interface VueConstructor {
    $form: Form;
  }
}
