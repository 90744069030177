import { getModule, Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { to } from 'await-to-js';
import store from '@/store';

import { singleSlipService } from './singleSlip.service';

import { OddStatus, Slip } from '../slip';
import isEmpty from 'lodash/isEmpty';
import {
  IMutateOddStatus,
  IResetSlipOdd,
  IResolveSlipOddsPayload,
  ISetOddForResolving,
} from './singleSlip.types';
import {
  RESOLVE_ACTIONS_ODD_STATUS_MAP,
  RESOLVE_SLIP_ODDS_ACCESSORS,
} from './singleSlip.constants';

const initialState = () => ({
  totalSlips: 0,
  slips: [],
  selectedSlipId: '',
  selectedSlip: {},

  deniedPayoutTicketNumber: 0,
  previewSlipId: '',
  isSlipEdited: false,
  slipIdToAvoidAutoDeny: '',
  resolveOddsPayload: {
    LOST: [],
    WON: [],
    ODD_ONE: [],
  },
});

@Module({ dynamic: true, store, name: 'singleSlip', namespaced: true })
class SingleSlip extends VuexModule {
  totalSlips = initialState().totalSlips;
  slips: Slip[] = initialState().slips;
  selectedSlipId = initialState().selectedSlipId;
  selectedSlip: Slip = initialState().selectedSlip as Slip;
  resolveOddsPayload: IResolveSlipOddsPayload = initialState().resolveOddsPayload;
  deniedPayoutTicketNumber = initialState().deniedPayoutTicketNumber;

  get activeSlipEvents() {
    if (isEmpty(this.selectedSlip)) return null;
    return singleSlipService.mapGroupToSlipEvents(this.selectedSlip as Slip);
  }

  get isResolveOddsPayloadValid() {
    return RESOLVE_SLIP_ODDS_ACCESSORS.some(key => this.resolveOddsPayload[key].length > 0);
  }

  get canCancelSlipPayout() {
    if (!this.selectedSlip?.short_uuid) return false;
    return singleSlipService.getCanCancelSlipPayout(this.selectedSlip);
  }

  get canApprovePayout() {
    if (!this.selectedSlip?.short_uuid) return false;
    return singleSlipService.areAllOddsResolved(this.selectedSlip);
  }

  @Mutation
  toggleOddForResolving({ oddId, resolveAction }: ISetOddForResolving) {
    const resolvingArray = this.resolveOddsPayload[resolveAction];
    if (resolvingArray.includes(oddId)) {
      this.resolveOddsPayload[resolveAction] = resolvingArray.filter(id => oddId !== id);
    } else {
      resolvingArray.push(oddId);
    }
    singleSlipService.filterSelectedOdds(this.resolveOddsPayload, { oddId, resolveAction });
  }

  @Action
  setOddForResolving({ oddId, resolveAction }: ISetOddForResolving) {
    this.toggleOddForResolving({ oddId, resolveAction });
  }

  @Mutation
  setSlips(slips: Slip[]) {
    this.slips = slips;
  }

  @Mutation
  setSelectedSlip(slip: Slip) {
    this.selectedSlip = slip;
    this.selectedSlipId = slip.short_uuid;
  }

  @Mutation
  clearSelectedSlip() {
    this.selectedSlipId = '';
    this.selectedSlip = {} as Slip;
  }

  @Mutation
  setTotalSlips(slipCount: number) {
    this.totalSlips = slipCount;
  }

  @Mutation
  removeSlip(slipId: string) {
    this.slips = this.slips.filter(slip => slip.short_uuid !== slipId);
  }

  @Mutation
  resetStore() {
    const initial = initialState() as any;
    Object.keys(initial).forEach(key => {
      this[key as keyof this] = initial[key];
    });
  }

  @Action
  setSelected(slipId: string) {
    // used for million tickets
    this.clearSelectedSlip();
    const slip = singleSlipService.findSlip(slipId, this.slips);
    this.setSelectedSlip(slip);
  }

  @Mutation
  setDeniedPayoutTicketNumber(count: number) {
    this.deniedPayoutTicketNumber = count;
  }

  @Action
  async getDeniedPayoutTicketNumber() {
    const [err, res] = await to(singleSlipService.getDeniedPayoutTicketNumber());
    if (err) return Promise.reject(err);
    this.setDeniedPayoutTicketNumber((res as any).count);
  }

  @Action
  async getMillionSlips() {
    const [err, res] = await to(singleSlipService.getMillionSlips());
    if (err) return Promise.reject(err);
    this.setSlips((res as any).results);
    this.setTotalSlips((res as any).count);
  }

  @Action
  async approveSlip(slipId: string) {
    const [err] = await to<any>(singleSlipService.approveMillionSlip(slipId));
    if (err) return Promise.reject(err);
    this.removeSlip(slipId);
    this.clearSelectedSlip();
    return Promise.resolve();
  }

  @Action
  async denySlip(slipId: string) {
    const [err] = await to<any>(singleSlipService.denySingleSlipPayout(slipId));
    if (err) return Promise.reject(err);
    this.removeSlip(slipId);
    this.clearSelectedSlip();
    return Promise.resolve();
  }

  @Mutation
  setOddStatus({ oddId, oddStatus }: IMutateOddStatus) {
    const eventsByGroup = this.selectedSlip.slip_groups.map(g => g.events);
    const allEvents = eventsByGroup.flat();
    const allOdds = allEvents.map(e => e.odds).flat();
    const selectedOdd = allOdds.find(o => o.id === oddId);
    if (!selectedOdd) return;
    selectedOdd.automated_status = oddStatus;
  }

  @Action
  async resetSlipOdd({ oddId }: IResetSlipOdd) {
    const [err] = await to<any>(singleSlipService.resetSlipOdd(oddId));
    if (err) return Promise.reject(err);
    this.setOddStatus({ oddId, oddStatus: OddStatus.NOT_RESOLVED });
    return Promise.resolve();
  }

  @Mutation
  clearOddsStatusPayload() {
    this.resolveOddsPayload = initialState().resolveOddsPayload;
  }

  @Action
  async resolveOdds() {
    const [err] = await to<any>(singleSlipService.resolveOdds(this.resolveOddsPayload));
    if (err) return Promise.reject(err);
    RESOLVE_SLIP_ODDS_ACCESSORS.forEach(key => {
      this.resolveOddsPayload[key].forEach(oddId => {
        this.setOddStatus({ oddId, oddStatus: RESOLVE_ACTIONS_ODD_STATUS_MAP[key] });
      });
    });
    this.clearOddsStatusPayload();
    return Promise.resolve();
  }

  @Action
  async loadSlipById(slipId: string) {
    const [err, res] = await to<any>(singleSlipService.getSlipById(slipId));
    if (err) return Promise.reject(err);
    if (res.results.length !== 1) return Promise.reject('error');
    this.setSelectedSlip(res.results[0]);
  }
}

export const SingleSlipStore = getModule(SingleSlip);
