export enum SportCode {
  FOOTBALL = 'fud',
  BASKETBALL = 'kos',
  VOLEYBALL = 'odb',
  HANDBALL = 'ruk',
  TENNIS = 'ten',
  WATERPOLO = 'vat',
  HOCKEY = 'hok',
  AMERICAN_FOOTBALL = 'afu',
  DARTS = 'pik',
  SNOOKER = 'snu',
  FUTSAL = 'fut',
  BASEBALL = 'bej',
  BADMINTON = 'bad',
  RUGBY = 'rug',
  AUSSIE_RULES = 'aus',
  ESOCCER = 'eso',
  SQUASH = 'squ',
  EBASKETBALL = 'eba',
  BEACH_VOLLEY = 'bvo',
  BASKETBALL_3X3 = '3x3',
  BOWLS = 'bow',
  CRICKET = 'cri',
  TABLE_TENNIS = 'ste',
  FIELD_HOCKEY = 'fho',
  COUNTER_STRIKE = 'cst',
  DOTA2 = 'dt2',
  LEAUGE_OF_LEGENDS = 'lol',
  MMA = 'mma',
}

export enum SportId {
  FOOTBALL = 1,
  BASKETBALL = 2,
  VOLEYBALL = 3,
  HANDBALL = 4,
  TENNIS = 5,
  WATERPOLO = 6,
  HOCKEY = 7,
  AMERICAN_FOOTBALL = 8,
  DARTS = 9,
  SNOOKER = 10,
  FUTSAL = 11,
  TABLE_TENNIS = 12,
  BASEBALL = 13,
  BASKET_3X3 = 24,
  MMA = 60490,
}

const SportIds = {
  FOOTBALL: 1,
  BASKETBALL: 2,
  VOLEYBALL: 3,
  HANDBALL: 4,
  TENNIS: 5,
  WATERPOLO: 6,
  HOCKEY: 7,
  AMERICAN_FOOTBALL: 8,
  DARTS: 9,
  SNOOKER: 10,
  FUTSAL: 11,
  TABLE_TENNIS: 12,
  BASEBALL: 13,
  BASKET_3X3: 24,
  MMA: 60490,
};

export const SPORT_IDS_WITH_SETS = [
  SportIds.VOLEYBALL,
  SportIds.TENNIS,
  SportIds.TABLE_TENNIS,
  SportIds.DARTS,
  SportIds.SNOOKER,
];

export const HAS_GOES_TROUGH: { [key: string]: string } = {
  [SportId.FOOTBALL]: SportCode.FOOTBALL,
  [SportId.BASKETBALL]: SportCode.BASKETBALL,
  [SportId.HANDBALL]: SportCode.HANDBALL,
};

export type SportShortCode = keyof typeof SportCode;

export const DEFAULT_SORT = [
  SportCode.FOOTBALL,
  SportCode.BASKETBALL,
  SportCode.TENNIS,
  SportCode.HANDBALL,
  SportCode.VOLEYBALL,
  SportCode.HOCKEY,
];

export const SPORT_TIME_LINE = {
  [SportCode.FOOTBALL]: [0, 5, 15, 30, 35, 45, 60, 75, 80, 90, 105],
  [SportCode.BASKETBALL]: [0, 5, 10, 15, 20, 30, 35, 40],
  [SportCode.TENNIS]: [0],
  [SportCode.HANDBALL]: [0, 15, 30, 45, 50, 60],
  [SportCode.VOLEYBALL]: [0],
  [SportCode.HOCKEY]: [0, 15, 20, 35, 40, 50, 55, 60],
  [SportCode.FUTSAL]: [0, 10, 20, 30, 35, 40],
  [SportCode.AMERICAN_FOOTBALL]: [0, 10, 15, 20, 30, 45, 55, 60],
};

// Dont show important period results unless that period has passed
// Obj below dictates at which minute that important period passes
export const SPORT_IMPORTANT_PERIOD_TIME_LIMIT: { [key: string]: number } = {
  [SportCode.FOOTBALL]: 45,
  [SportCode.BASKETBALL]: 20,
  [SportCode.HOCKEY]: 20,
  [SportCode.HANDBALL]: 20,
  [SportCode.FUTSAL]: 20,
  [SportCode.AMERICAN_FOOTBALL]: 30,
};

export const SPORT_WHEN_TO_HIDE_PERIOD_RESULT: { [key: string]: string[] } = {
  [SportCode.FOOTBALL]: ['NSY', 'FH'],
  [SportCode.BASKETBALL]: ['NSY', 'Q1', 'Q2'],
  [SportCode.HOCKEY]: ['NSY', 'P1'],
  [SportCode.HANDBALL]: ['NSY', 'FH'],
  [SportCode.FUTSAL]: ['NSY', 'FH'],
  [SportCode.AMERICAN_FOOTBALL]: ['NSY', 'Q1', 'Q2'],
  [SportCode.VOLEYBALL]: ['NSY'],
  [SportCode.TENNIS]: ['NSY'],
  [SportCode.TABLE_TENNIS]: ['NSY'],
};
