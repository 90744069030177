import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import to from 'await-to-js';

import store from '@/store';
import { slipService } from './slip.service';
import { Slip as ISlip, SlipStatus } from './slip.types';
import { slipRepo } from './slip.repo';

@Module({ dynamic: true, store, name: 'slip', namespaced: true })
class Slip extends VuexModule {
  slips: ISlip[] = [];
  totalSlips = 0;
  activeSlip = '';
  newSlipAdded = {};
  lastUpdatedSlip = '';
  arrayOfIndex = [];

  get selectedSlip() {
    return this.slips.find(s => s.id === this.activeSlip);
  }

  @Mutation
  setActiveSlip(slipId: string) {
    this.activeSlip = slipId;
  }

  @Mutation
  cancelSlipInStore(shortUuid: string) {
    this.slips.forEach(slip => {
      if (slip.short_uuid === shortUuid) {
        slip.status = SlipStatus.CANCELED;
      }
    });
  }

  @Mutation
  setSlips(responseFromApi: any) {
    this.totalSlips = responseFromApi.count;
    this.slips = [...this.slips, ...responseFromApi.results];
  }

  @Mutation
  updateSlip(slip: any) {
    const foundIndex = this.slips.findIndex(s => s.short_uuid === slip.short_uuid);
    if (foundIndex !== -1) {
      this.slips[foundIndex] = { ...this.slips[foundIndex], ...slip };
      this.lastUpdatedSlip = `${slip.short_uuid}_${Date.now()}`;
    }
  }

  @Mutation
  addNewSlip(slip: any) {
    this.newSlipAdded = slip;
  }

  @Mutation
  clearStore() {
    this.totalSlips = 0;
    this.slips = [];
    this.activeSlip = '';
  }

  @Action
  async fetchSlipToUpdate(params: any) {
    const [_err, result] = await to<any>(slipRepo.getSlips(params, {}));
    this.updateSlip(result.results[0]);
  }

  @Action
  async fetchSlips(params: any) {
    const [_err, results] = await to<any>(slipService.getSlips(params));
    this.setSlips(results);
  }

  @Action
  async cancelSlip(shortUuid: string) {
    const [err] = await to(slipService.cancelSlip(shortUuid));
    if (err) return Promise.reject(err);
    this.cancelSlipInStore(shortUuid);
  }

  @Action
  newSlipFromFeed(slip: any) {
    const newSlip = slipService.mapSlipFromFeed(slip);
    this.addNewSlip(newSlip);
  }

  @Action
  async slipStatusUpdate(wsData: any) {
    const newSlipStatusData = slipService.mapSlipStatusUpdate(wsData);
    this.updateSlip(newSlipStatusData);
  }
}

export const SlipStore = getModule(Slip);
