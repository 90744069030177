import { i18n } from '@/ui/locales/setupLocale';
import { ISlipStatusConfig, SlipStatus } from './slip.types';

export const REPORT_TYPES = [
  { label: i18n.t('slip.slips'), value: 'slip' },
  { label: i18n.t('common.users'), value: 'users' },
  { label: i18n.t('slip.playerReports'), value: 'player_reports' },
  { label: i18n.t('slip.place'), value: 'places' },
];

export const SLIP_TYPES = [
  { label: i18n.t('common.prematch'), value: 'PREMATCH' },
  { label: i18n.t('common.live'), value: 'LIVE' },
  { label: i18n.t('common.mix'), value: 'MIX' },
  { label: i18n.t('common.outright'), value: 'OUTRIGHT' },
];

export const EVENT_TYPES = [
  { label: i18n.t('common.dual'), value: 'D' },
  { label: i18n.t('common.player'), value: 'P' },
  { label: i18n.t('common.antepost'), value: 'A' },
];

export const SLIP_ENV = [
  { label: i18n.t('common.online'), value: 'web' },
  { label: i18n.t('common.retail'), value: 'landbase' },
];

export const LIVE_SLIP_STATUS_OPTIONS = [
  {
    label: i18n.t('slip.open'),
    value: SlipStatus.NOT_RESOLVED,
  },
  {
    label: i18n.t('slip.canceled'),
    value: SlipStatus.CANCELED,
  },
  {
    label: i18n.t('slip.lost'),
    value: SlipStatus.FAILED,
  },
  {
    label: i18n.t('slip.paidOut'),
    value: SlipStatus.PAYED_OUT,
  },
  {
    label: i18n.t('slip.denied'),
    value: SlipStatus.DENIED,
  },
];

export const REPORTS_SLIP_STATUS_OPTIONS = [
  {
    label: i18n.t('slip.won'),
    value: SlipStatus.PASSED,
  },
  {
    label: i18n.t('slip.lost'),
    value: SlipStatus.FAILED,
  },
  {
    label: i18n.t('slip.canceled'),
    value: SlipStatus.CANCELED,
  },
  {
    label: i18n.t('slip.open'),
    value: SlipStatus.NOT_RESOLVED,
  },
  {
    label: i18n.t('slip.awaitingApproval'),
    value: SlipStatus.APPROVING,
  },
  {
    label: i18n.t('slip.denied'),
    value: SlipStatus.DENIED,
  },
  {
    label: i18n.t('slip.cashback'),
    value: SlipStatus.CASHBACK,
  },
  {
    label: i18n.t('slip.paidOut'),
    value: SlipStatus.PAYED_OUT,
  },
  {
    label: i18n.t('slip.open'),
    value: SlipStatus.MANUAL_APPROVAL_CHANGED,
  },
];

export const SLIP_STATUS_COLORS: { [key in SlipStatus]?: ISlipStatusConfig } = {
  [SlipStatus.PASSED]: {
    text: i18n.t('slip.won'),
    color: '#008002',
    winLabelText: i18n.t('slip.winnings'),
    bonusLabelText: i18n.t('slip.bonus'),
    totalWinLabelText: i18n.t('slip.totalWinnings'),
    textColor: 'white',
  },
  [SlipStatus.APPROVING]: {
    text: i18n.t('slip.open'),
    color: '#DDEBF7',
    winLabelText: i18n.t('slip.potentialWinnings'),
    bonusLabelText: i18n.t('slip.potentialBonus'),
    totalWinLabelText: i18n.t('slip.potentialTotalWinnings'),
    textColor: '#4b4b4b',
  },
  [SlipStatus.MANUAL_APPROVAL_CHANGED]: {
    text: i18n.t('slip.open'),
    color: '#DDEBF7',
    winLabelText: i18n.t('slip.potentialWinnings'),
    bonusLabelText: i18n.t('slip.potentialBonus'),
    totalWinLabelText: i18n.t('slip.potentialTotalWinnings'),
    textColor: '#4b4b4b',
  },
  [SlipStatus.PAYED_OUT]: {
    text: i18n.t('slip.paidOut'),
    color: '#008002',
    winLabelText: i18n.t('slip.winnings'),
    bonusLabelText: i18n.t('slip.bonus'),
    totalWinLabelText: i18n.t('slip.totalWinnings'),
    textColor: 'white',
  },
  [SlipStatus.CASHBACK]: {
    text: i18n.t('slip.cashback'),
    color: '#008002',
    winLabelText: i18n.t('slip.winnings'),
    bonusLabelText: i18n.t('slip.bonus'),
    totalWinLabelText: i18n.t('slip.totalWinnings'),
    textColor: 'white',
  },
  [SlipStatus.FAILED]: {
    text: i18n.t('slip.lost'),
    color: '#FF0100',
    winLabelText: i18n.t('slip.potentialWinnings'),
    bonusLabelText: i18n.t('slip.potentialBonus'),
    totalWinLabelText: i18n.t('slip.potentialTotalWinnings'),
    textColor: 'white',
  },
  [SlipStatus.NOT_RESOLVED]: {
    text: i18n.t('slip.open'),
    color: '#DDEBF7',
    winLabelText: i18n.t('slip.potentialWinnings'),
    bonusLabelText: i18n.t('slip.potentialBonus'),
    totalWinLabelText: i18n.t('slip.potentialTotalWinnings'),
    textColor: '#4b4b4b',
  },
  [SlipStatus.CANCELED]: {
    text: i18n.t('slip.canceled'),
    color: '#FFA500',
    winLabelText: i18n.t('slip.potentialWinnings'),
    bonusLabelText: i18n.t('slip.potentialBonus'),
    totalWinLabelText: i18n.t('slip.potentialTotalWinnings'),
    textColor: 'white',
  },
  [SlipStatus.DENIED]: {
    text: i18n.t('slip.denied'),
    color: '#ffdf00',
    winLabelText: i18n.t('slip.potentialWinnings'),
    bonusLabelText: i18n.t('slip.potentialBonus'),
    totalWinLabelText: i18n.t('slip.potentialTotalWinnings'),
    textColor: '#4b4b4b',
  },
};

export const SLIP_DENIAL_BY = {
  W: 'WEB USER',
  O: 'SHOPCLIENT OPERATOR',
  A: 'AUTOMATIC',
  C: 'BACKOFFICE OPERATOR',
};
