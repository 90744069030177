// import Vue from 'vue';
import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { FilterConfig, FilterValue } from './filter.types';

const initialState = () => ({
  config: [],
  value: {},
  isOpen: false,
  loading: false,
});

@Module({ dynamic: true, store, name: 'filter', namespaced: true })
class Filter extends VuexModule {
  config: FilterConfig = [];
  value: FilterValue = {};
  isOpen = false;
  loading = initialState().loading;

  get hasActiveFilter() {
    return (
      Object.values(this.value)
        .filter(a => a !== undefined)
        .filter(a => a !== '').length > 0
    );
  }

  get activeFilters() {
    return Object.entries(this.value).reduce(
      (a: any, [k, v]) => (v === undefined || v === '' ? a : ((a[k] = v), a)),
      {}
    );
  }

  get isFilterConfigured() {
    return Object.keys(this.config).length > 0;
  }

  @Mutation
  private setConfig(config: FilterConfig) {
    this.config = config;
  }

  @Mutation
  private setValue({
    key,
    value,
  }: {
    key: string | string[];
    value: string | null | string[] | boolean;
  }) {
    if (
      (typeof value === 'string' ||
        typeof value === 'boolean' ||
        typeof value === 'undefined' ||
        value === null) &&
      typeof key === 'string'
    ) {
      this.value = { ...this.value, [key]: value };
    } else if (Array.isArray(key) && typeof value !== 'boolean') {
      key.forEach((singleKey, index) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.value = { ...this.value, [singleKey]: value![index] };
      });
    }
  }

  @Mutation
  private setInitialValue(config: FilterConfig) {
    config.forEach(({ key, defaultValue }) => {
      if (Array.isArray(key)) {
        key.forEach(k => {
          this.value[k] = defaultValue;
        });
      } else {
        this.value[key] = defaultValue;
      }
    });
  }

  @Mutation
  resetStore() {
    const initial = initialState() as any;
    Object.keys(initial).forEach(key => {
      this[key as keyof this] = initial[key];
    });
  }

  @Mutation
  toggle() {
    this.isOpen = !this.isOpen;
  }

  @Mutation
  setLoading(value: boolean) {
    this.loading = value;
  }

  @Action
  init(config: FilterConfig) {
    this.resetStore();
    this.setConfig(config);
    this.setInitialValue(config);
  }

  @Action
  onValueChange(options: { key: string | string[]; value: any }) {
    this.setValue(options);
  }

  @Action
  clearFilter() {
    this.setInitialValue(this.config);
  }
}

export const FilterStore = getModule(Filter);
