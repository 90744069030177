const hlCache = new Map();

/**
 * Usage <div v-highlight="message"> {{ message }}</div>
 */
export const highlight = {
  bind(el: HTMLElement, { value }: any) {
    hlCache.set(el, value);
  },
  componentUpdated(el: HTMLElement, { value }: any) {
    if (hlCache.get(el) !== value) {
      hlCache.set(el, value);
      el.classList.remove('highlight');
      el.classList.add('highlight');

      setTimeout(() => {
        el.classList.remove('highlight');
      }, 600);
    }
  },
  unbind(el: HTMLElement) {
    hlCache.delete(el);
  },
};
