
import { AvailableRoutes } from '@/ui/types/route.types';
import Vue from 'vue';

import { Component } from 'vue-property-decorator';

const SHOW_MODAL_AFTER_MS = 10000;
const ENABLED_ON_PAGES = [
  AvailableRoutes.slipApproval,
  AvailableRoutes.eventManagmentDual,
  AvailableRoutes.liveEvents,
  AvailableRoutes.liveTickets,
  AvailableRoutes.antepostManagment,
  AvailableRoutes.playerEvents,
  AvailableRoutes.prematchDual,
  AvailableRoutes.prematchPlayer,
  AvailableRoutes.prematchAntepost,
  AvailableRoutes.liveNonBooked,
  AvailableRoutes.manualSettlement,
  AvailableRoutes.playerSettlement,
  AvailableRoutes.allTickets,
];

enum FeedStatus {
  Init,
  Online,
  Offline,
}

@Component
export default class WsStatus extends Vue {
  feedStatus = FeedStatus.Init;
  timer = 0;
  isModalVisible = false;

  get color() {
    return this.feedStatus === FeedStatus.Online ? 'green' : 'red';
  }

  get isEnabled() {
    return ENABLED_ON_PAGES.includes(this.$route.path as any);
  }

  mounted() {
    document.addEventListener('feed_open', () => {
      this.onFeedOpen();
    });

    document.addEventListener('feed2_open', () => {
      this.onFeedOpen();
    });

    document.addEventListener('feed_close', () => {
      this.waitForReconnection();
    });

    document.addEventListener('feed2_close', () => {
      this.waitForReconnection();
    });
  }

  destroyed() {
    clearInterval(this.timer);
    document.removeEventListener('feed_open', this.onFeedOpen, true);
    document.removeEventListener('feed2_open', this.onFeedOpen, true);
    document.removeEventListener('feed_close', this.onFeedClose, true);
    document.removeEventListener('feed2_close', this.onFeedOpen, true);
  }

  waitForReconnection() {
    if (!this.isEnabled) return;
    this.feedStatus = FeedStatus.Offline;
    this.timer = setTimeout(() => {
      this.onFeedClose();
    }, SHOW_MODAL_AFTER_MS);
  }

  onFeedOpen() {
    if (!this.isEnabled) return;
    clearInterval(this.timer);
    this.feedStatus = FeedStatus.Online;
  }

  onFeedClose() {
    if (!this.isEnabled) return;
    if (this.feedStatus === FeedStatus.Online) {
      clearInterval(this.timer);
      return;
    }
    this.openModal();
  }

  openModal() {
    if (!this.isEnabled) return;
    if (this.isModalVisible) return;
    this.isModalVisible = true;
    this.$error({
      title: 'An error occured',
      okText: 'Reload',
      content: 'Connection lost. Please reload page to recconect.',
      onOk: () => {
        window.location.reload();
      },
    });
  }
}
