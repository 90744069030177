
import Component, { mixins } from 'vue-class-component';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { TranslateResult } from 'vue-i18n';
import to from 'await-to-js';
import { NetworkStatus, WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import { AuthStore, LoginResponse } from '@/modules/auth';
import { ONLY_APPROVAL_KEY, ONLY_WITHDRAWAL_KEY } from '@/constants';
import { AvailableRoutes } from '@/ui/types/route.types';

@Component
export default class LoginPage extends mixins(WithNetworkStatus) {
  loginForm!: WrappedFormUtils;
  errorMessage: TranslateResult = '';

  beforeCreate() {
    this.loginForm = this.$form.createForm(this);
  }

  get isApprovalOnlyMode() {
    return !!localStorage.getItem(ONLY_APPROVAL_KEY);
  }

  get isWithdrawalOnlyMode() {
    return !!localStorage.getItem(ONLY_WITHDRAWAL_KEY);
  }

  get redirectAfterLoginUrl() {
    if (this.isApprovalOnlyMode) {
      return AvailableRoutes.approval;
    }
    if (this.isWithdrawalOnlyMode) {
      return AvailableRoutes.withdrawValidation;
    }
    return AvailableRoutes.createOffer;
  }

  async onSubmit(e: any) {
    e.preventDefault();
    this.errorMessage = '';
    this.loginForm.validateFields(async (err: any) => {
      if (err) return;
      const { loginForm } = this;
      const username = loginForm.getFieldValue('username');
      const password = loginForm.getFieldValue('password');

      this.setNetworkStatus(NetworkStatus.InProgress);
      const [error, response] = await to<LoginResponse, any>(
        AuthStore.login({ username, password })
      );
      this.setNetworkStatus(NetworkStatus.Success);
      if (response && 'token' in response) {
        this.$router.push(this.redirectAfterLoginUrl);
      } else {
        loginForm.resetFields();
      }

      if ((error && error.status === 401) || (error && !error.status)) {
        this.errorMessage = this.$t('login.invalidCredentials');
        return;
      }

      if (error) {
        this.errorMessage = error;
      }
    });
  }
}
