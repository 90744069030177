import Vue from 'vue';
import Component from 'vue-class-component';

export enum NetworkStatus {
  Idle,
  Success,
  Error,
  InProgress,
}

@Component
export class WithNetworkStatus extends Vue {
  networkStatus = NetworkStatus.Idle;

  get isLoading() {
    return this.networkStatus === NetworkStatus.InProgress;
  }

  get hasError() {
    return this.networkStatus === NetworkStatus.Error;
  }

  get isSuccess() {
    return this.networkStatus === NetworkStatus.Success;
  }

  setNetworkStatus(state: NetworkStatus) {
    this.networkStatus = state;
  }
}
