import Vue from 'vue';
import { config } from 'vuex-module-decorators';
import VueVirtualScroller from 'vue-virtual-scroller';
import InfiniteLoading from 'vue-infinite-loading';
import VueWorker from 'vue-worker';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import App from '@/App.vue';
import router from '@/ui/router';
import store from '@/store';
import { setupAntd } from '@/ui/plugins';
import { i18n } from '@/ui/locales/setupLocale';
import '@/ui/styles/style.less';
import { setupSimplebar } from './ui/plugins/simplebar';
import { registerGlobalFilters } from './ui/filters';
import { registerGlobalDirectives } from './ui/directives';
import { setupShortKey } from './ui/plugins/shortkey';
import { setupDayjs } from '@/ui/plugins/dayjs';
import { Popconfirm } from '@/ui/components/Popconfirm';

setupAntd();
setupSimplebar();
registerGlobalFilters(Vue);
registerGlobalDirectives(Vue);
setupShortKey();
setupDayjs();
Vue.use(VueVirtualScroller);
Vue.use(InfiniteLoading);
Vue.use(VueWorker);

Vue.config.productionTip = false;
config.rawError = true;

Vue.mixin({
  computed: {
    console: () => console,
  },
});

Vue.component('popconfirm', Popconfirm);

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
