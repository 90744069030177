import { VueConstructor } from 'vue';

import date from './date';
import odd from './odd';
import currency from './currency';
import boolean from './boolean';
import startCase from './startCase';

export const registerGlobalFilters = (Vue: VueConstructor<Vue>) => {
  Vue.filter('date', date);
  Vue.filter('odd', odd);
  Vue.filter('currency', currency);
  Vue.filter('boolean', boolean);
  Vue.filter('startCase', startCase);
};
