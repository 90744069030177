const TOKEN_KEY = 'token';
const USER_PERMISSIONS = 'userPermissions';

export const onLogout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_PERMISSIONS);
  window.location.href = '/';
};

export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const saveRole = ({
  role,
  isAdmin,
  userId,
}: {
  role: number;
  isAdmin: boolean;
  userId: number;
}) => {
  localStorage.setItem(USER_PERMISSIONS, JSON.stringify({ role, isAdmin, userId }));
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    return `Bearer ${token}`;
  }
  return null;
};

export const getRole = () => {
  const role = localStorage.getItem(USER_PERMISSIONS);
  if (role) {
    return JSON.parse(role);
  }
  return null;
};

export const getUserId = () => {
  const user = localStorage.getItem(USER_PERMISSIONS);
  if (user) {
    return JSON.parse(user).userId;
  }
};

export const getIsAdmin = () => {
  const isAdmin = localStorage.getItem(USER_PERMISSIONS);
  if (isAdmin) {
    return JSON.parse(isAdmin);
  }
  return null;
};
