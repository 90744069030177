import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import to from 'await-to-js';

import store from '@/store';
import { sportService } from './sport.service';
import { Sport as ISport, Sports } from './sport.types';
import { SportShortCode } from './sport.contants';

@Module({ dynamic: true, store, name: 'sport', namespaced: true })
class Sport extends VuexModule {
  sports: ISport[] | null = null;
  allSports: Sports[] | [] = [];
  // change here will change on all pages
  sportsWithPlayers = [1, 2, 4, 6];
  sportsWithLivePlayers = [1];

  get sortedAllSports() {
    return sportService.sort(this.allSports);
  }

  get sportTimeline() {
    return (sportCode: SportShortCode) => sportService.getTimeline(sportCode);
  }

  get getSportById() {
    return (id: number | undefined) => this.allSports.find(sport => sport.id === id);
  }

  @Mutation
  private setSports(data: ISport[]): void {
    this.sports = data;
  }
  @Mutation
  private setAllSports(data: Sports[]): void {
    this.allSports = sportService.sort(data);
  }

  @Action
  async getSportsWithCompetitions() {
    const [err, response] = await to(sportService.getSportsWithCompetitions());
    if (!err && response) {
      this.setSports(response as any);
    }
  }
  @Action
  async fetchSports() {
    const [err, response] = await to(sportService.fetchSports());
    if (!err && response) {
      this.setAllSports(response);
    }
  }
}

export const SportStore = getModule(Sport);
