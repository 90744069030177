
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class Popconfirm extends Vue {
  isOpen = false;

  @Prop()
  title!: string;

  @Prop({ default: 'Yes' })
  okText!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: null })
  visible!: boolean;

  @Prop({ default: 'No' })
  cancelText!: string;

  @Prop({ default: 'top' })
  placement!: string;

  @Prop()
  overlayStyle!: {};

  @Prop()
  popoverClass!: string;

  get controlledOutside() {
    return this.visible !== null;
  }

  get trigger() {
    if (this.disabled || this.controlledOutside) return 'none';
    return 'click';
  }

  get isVisible() {
    if (this.controlledOutside) {
      return this.visible;
    }
    return this.isOpen;
  }

  @Watch('isVisible')
  onIsOpen(val: boolean) {
    this.isOpen = val;
  }

  hide() {
    this.isOpen = false;
    this.$emit('cancel');
  }

  onOk() {
    this.isOpen = false;
    this.$emit('confirm');
  }

  keyAction({ srcKey }: any) {
    if (srcKey === 'save') {
      this.onOk();
    } else {
      this.hide();
    }
  }

  prop() {
    if (this.controlledOutside) {
      return {
        visible: this.isVisible,
      };
    } else {
      return {
        model: this.isOpen,
      };
    }
  }
}
