import axios, { AxiosInstance, CancelTokenSource } from 'axios';

class RequestManager {
  instance: AxiosInstance;
  requests: CancelTokenSource[];

  constructor(axiosInstance: AxiosInstance) {
    this.instance = axiosInstance;
    this.requests = [];
    this.addInterceptors();
  }

  addInterceptors() {
    this.instance.interceptors.request.use(config => {
      const source = axios.CancelToken.source();
      this.requests.push(source);
      return {
        cancelToken: source.token,
        ...config,
      };
    });
  }

  cancelAll() {
    this.requests.forEach(request => {
      request.cancel();
    });
    this.requests = [];
  }
}

export { RequestManager };
