import to from 'await-to-js';
import camelCase from 'lodash/camelCase';

import { DEFAULT_SORT, SPORT_TIME_LINE } from './sport.contants';
import { sportRepo } from './sport.repo';
import { Sport, Sports } from './sport.types';
import { FiltersSport, isFilterSport } from '../sportFilter/sportFilter.types';
import { CompetitionOrSport } from '../pendingTime/pendingTime.types';
import { ICompetitor } from '@/modules/competitor';
import { ICompetition } from '@/modules/competition';

class SportService {
  async getSportsWithCompetitions() {
    const [err, res] = await to(sportRepo.getSportsWithCompetitions());
    if (res) {
      return Promise.resolve(this.sort(res));
    } else {
      return Promise.reject(err);
    }
  }

  fetchSports() {
    return sportRepo.fetchSports();
  }

  sortLocationsInSports(sports: FiltersSport[]) {
    return sports.map(s => {
      s.locations = s.locations.slice().sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      return s;
    });
  }

  sort(sport: Sport[] | Sports[] | FiltersSport[]): any {
    if (!sport.length) return [];
    const sportsArray = isFilterSport(sport[0])
      ? this.sortLocationsInSports(sport as FiltersSport[])
      : sport;
    return sportsArray.slice().sort((a: any, b: any) => {
      const index1 = DEFAULT_SORT.indexOf(a.code);
      const index2 = DEFAULT_SORT.indexOf(b.code);
      return (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity);
    });
  }

  normalizeName(name: string) {
    return camelCase(name);
  }

  getTimeline(name: string): number[] {
    return SPORT_TIME_LINE[name as keyof typeof SPORT_TIME_LINE] || [0];
  }

  hasSport(c: ICompetition | ICompetitor | null) {
    return c && c.sport && c.sport.name;
  }

  allCompetitions(sports: Sport[] | null) {
    if (!sports) return [];
    return sports.reduce((allSports, sport) => {
      const competitionsByLocation = sport.locations?.map(l => l.competitions);
      const idKeyObj = competitionsByLocation?.reduce((allCompettitions, locations) => {
        const competitons: { [key: string]: CompetitionOrSport } = {};
        locations.forEach(l => {
          competitons[l.id] = l;
        });
        return { ...allCompettitions, ...competitons };
      }, {});
      return { ...allSports, ...idKeyObj };
    }, {});
  }

  allSports(sports: Sports[]) {
    if (!sports) return [];
    return sports.reduce((allSports, sport) => {
      const sportCountry: { [key: string]: CompetitionOrSport } = {};
      sportCountry[sport.id] = {
        ...sport,
      };
      return { ...allSports, ...sportCountry };
    }, {});
  }
}

export const sportService = new SportService();
