import moment from 'moment';

import { OddStatus, Slip } from '../slip';
import { DenialReason, SlipGroup } from '../slipApproval/slipApproval.types';
import { RESOLVE_SLIP_ODDS_ACCESSORS } from './singleSlip.constants';
import { singleSlipRepo } from './singleSlip.repo';
import { IResolveSlipOddsPayload, ISetOddForResolving } from './singleSlip.types';

class SingleSlipService {
  getMillionSlips() {
    return singleSlipRepo.getMillionSlips();
  }

  approveMillionSlip(slipId: string) {
    return singleSlipRepo.approveMillionSlip(slipId);
  }

  denySingleSlipPayout(slipId: string) {
    return singleSlipRepo.denySingleSlipPayout(slipId);
  }

  getSlipById(slipId: string) {
    return singleSlipRepo.getSlipById(slipId);
  }

  resetSlipOdd(oddId: string) {
    return singleSlipRepo.resetSlipOdd(oddId);
  }

  resolveOdds(resolveOddsPayload: IResolveSlipOddsPayload) {
    return singleSlipRepo.resolveOdds(resolveOddsPayload);
  }

  getPayoutLimit() {
    return singleSlipRepo.getPayoutLimit();
  }

  setPayoutLimit(value: number) {
    return singleSlipRepo.setPayoutLimit(value);
  }

  getDeniedPayoutTicketNumber() {
    return singleSlipRepo.getDeniedPayoutTicketNumber();
  }

  findSlip(slipId: string, slips: any) {
    return slips.find((slip: any) => slip.short_uuid === slipId);
  }

  mapGroupToSlipEvents(slip: Slip) {
    const events: any = [];
    slip.slip_groups.forEach((slipGroup: SlipGroup, index: number) => {
      slipGroup.events.forEach((event: any) => {
        events.push({
          ...event,
          group: `G-${index + 1}`,
          denial_reason: slip.denial_reason.errors?.find(
            (error: DenialReason) => Number(error.e_i_k) === Number(event.int_key)
          )?.error,
        });
      });
    });
    const sortedEventsByTime = events.sort((a: any, b: any) => {
      // format('x') converts date to miliseconds
      return (
        //@ts-ignore
        (moment(a.event_start_time).format('x') as number) - moment(b.event_start_time).format('x')
      );
    });
    return sortedEventsByTime;
  }

  filterSelectedOdds(
    resolveOddsPayload: IResolveSlipOddsPayload,
    { oddId, resolveAction }: ISetOddForResolving
  ) {
    const otherArrayKeys = RESOLVE_SLIP_ODDS_ACCESSORS.filter(key => key !== resolveAction);
    otherArrayKeys.forEach(key => {
      if (resolveOddsPayload[key].includes(oddId)) {
        resolveOddsPayload[key] = resolveOddsPayload[key].filter(id => oddId !== id);
      }
    });
  }

  areAllOddsResolved(slip: Slip) {
    return slip.slip_groups
      .map(sg => sg.events)
      .flat()
      .map(e => e.odds)
      .flat()
      .every(o => o.automated_status !== OddStatus.NOT_RESOLVED);
  }

  getCanCancelSlipPayout(slip: Slip) {
    if (slip.slip_env === 'landbase' && slip.payout_approved) return true; // a kladomat?
    return !this.areAllOddsResolved(slip) && slip.payout_approved;
  }
}

export const singleSlipService = new SingleSlipService();
